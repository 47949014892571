import styled from '@emotion/styled'

const FlexWrapper = styled.div({
  display: 'flex',
  minHeight: '100vh',
  maxWidth: 1440,
  margin: '0 auto',
})

export default FlexWrapper
