import React from 'react'
import styled from '@emotion/styled'
import { IconProceed } from '@apollo/space-kit/icons/IconProceed'
import { colors } from '../core/spacekit-colors'
import { breakpoints } from '../core'

const Container = styled.div({
  display: 'flex',
  flexShrink: 0,
  width: 240,
  [breakpoints.lg]: {
    width: 'auto',
    marginRight: 0,
  },
  [breakpoints.md]: {
    display: 'none',
  },
})

const StyledLink = styled.a({
  display: 'flex',
  alignItems: 'center',
  color: colors.indigo.dark,
  lineHeight: 2,
  textDecoration: 'none',
  ':hover': {
    color: colors.indigo.darker,
  },
})

const StyledIcon = styled(IconProceed)({
  height: '0.75em',
  marginLeft: '0.5em',
})

export default function HeaderButton() {
  return (
    <Container>
      <StyledLink href="/play" target="_blank">
        Launch GraphQL Playground
        <StyledIcon weight="thin" />
      </StyledLink>
    </Container>
  )
}
