/* eslint-disable no-unused-vars */
import React from 'react'
import styled from '@emotion/styled'
import { ReactComponent as CwIcon } from '../../assets/thecyberwire.svg'
import { ReactComponent as DocsIcon } from '../../assets/docs.svg'

const Wrapper = styled.div({
  display: 'flex',
  fontSize: 30,
})

const StyledCwIcon = styled(CwIcon)({
  height: '1em',
  marginRight: '0.2857142857em',
  marginLeft: '0.1em',
})

const StyledDocsIcon = styled(DocsIcon)({
  height: '0.7857142857em',
  marginTop: '0.07142857143em',
})

export default function Logo() {
  return (
    <Wrapper>
      <StyledCwIcon />
    </Wrapper>
  )
}
