/* eslint-disable no-unused-vars */
import '../prism.less'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { Button } from '@apollo/space-kit/Button'
import { Helmet } from 'react-helmet'
import { IconLayoutModule } from '@apollo/space-kit/icons/IconLayoutModule'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { size } from 'polished'
import { FlexWrapper, Layout, MenuButton, Sidebar, SidebarNav, breakpoints, colors, useResponsiveSidebar } from '../core'
import { MobileLogo } from './mobile-logo'
import HeaderButton from './header-button'
// import {Select} from './select';
import { SelectedLanguageContext } from './multi-code-block'
import { trackCustomEvent } from '../utils'
import Header from './header'

const Main = styled.main({
  flexGrow: 1,
})

const ButtonWrapper = styled.div({
  flexGrow: 1,
})

const StyledButton = styled(Button)({
  width: '100%',
  ':not(:hover)': {
    backgroundColor: colors.background,
  },
})

const StyledIcon = styled(IconLayoutModule)(size(16), {
  marginLeft: 'auto',
})

const MobileNav = styled.div({
  display: 'none',
  [breakpoints.md]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 32,
    color: colors.text1,
  },
})

const HeaderInner = styled.span({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 32,
})

const Eyebrow = styled.div({
  flexShrink: 0,
  padding: '8px 56px',
  backgroundColor: colors.background,
  color: colors.primary,
  fontSize: 14,
  position: 'sticky',
  top: 0,
  a: {
    color: 'inherit',
    fontWeight: 600,
  },
  [breakpoints.md]: {
    padding: '8px 24px',
  },
})

const GA_EVENT_CATEGORY_SIDEBAR = 'Sidebar'

function handleToggleAll(expanded) {
  trackCustomEvent({
    category: GA_EVENT_CATEGORY_SIDEBAR,
    action: 'Toggle all',
    label: expanded ? 'expand' : 'collapse',
  })
}

function handleToggleCategory(label, expanded) {
  trackCustomEvent({
    category: GA_EVENT_CATEGORY_SIDEBAR,
    action: 'Toggle category',
    label,
    value: Number(expanded),
  })
}

export default function PageLayout(props) {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            siteName
          }
        }
      }
    `
  )

  const { sidebarRef, openSidebar, sidebarOpen, handleWrapperClick, handleSidebarNavLinkClick } = useResponsiveSidebar()

  const [menuOpen, setMenuOpen] = useState(false)
  const selectedLanguageState = useLocalStorage('docs-lang')

  function openMenu() {
    setMenuOpen(true)
  }

  function closeMenu() {
    setMenuOpen(false)
  }

  const { pathname } = props.location
  const { siteName, title } = data.site.siteMetadata
  const { subtitle, sidebarContents, logoLink, menuTitle } = props.pageContext

  return (
    <Layout>
      <Helmet titleTemplate={['%s', subtitle, title].filter(Boolean).join(' - ')}>
        <meta name="robots" content="noindex" />
        <link rel="shortcut icon" href="/favicon.png" />
        <link rel="apple-touch-icon" href="/apple-touch-icon-144-precomposed.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144-precomposed.png" />
      </Helmet>
      <FlexWrapper onClick={handleWrapperClick}>
        <Sidebar responsive className="sidebar" open={sidebarOpen} ref={sidebarRef} title={siteName} logoLink={logoLink}>
          {sidebarContents && (
            <SidebarNav
              contents={sidebarContents}
              pathname={pathname}
              onToggleAll={handleToggleAll}
              onToggleCategory={handleToggleCategory}
              onLinkClick={handleSidebarNavLinkClick}
            />
          )}
        </Sidebar>
        <Main>
          <Header>
            <MobileNav>
              <MenuButton onClick={openSidebar} />
              <MobileLogo width={38} fill="currentColor" />
            </MobileNav>
            <div />
            <HeaderButton />
          </Header>
          <SelectedLanguageContext.Provider value={selectedLanguageState}>{props.children}</SelectedLanguageContext.Provider>
        </Main>
      </FlexWrapper>
    </Layout>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  pluginOptions: PropTypes.object.isRequired,
}
